import mitt, { type Emitter } from 'mitt'

import { type SupportDialogName } from '~/components/dialogs/DialogSupport.vue'
import { type visible } from '~/components/GlobalDialogs.vue'
import { type SearchData } from '~/components/SearchLink.vue'
import { type UserFilters } from '~/components/Users.vue'
import { type CaseFilters } from '~/composables/useCaseFilters.ts'
import { type Batch, type CaseForm, type CaseType, type Confidence, type Report } from '~/types.ts'

interface NewCaseFill {
  options?: { showClients: boolean; store?: typeof stores.case; redirectAfterSearch: boolean }
  form: Partial<CaseForm> & { clients?: number[]; confidence?: Confidence; onboarding_entity_id?: number }
  type?: CaseType
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Events = {
  'batch.created': Batch
  'batch.finished': string
  'cases.fetch': undefined
  'cases.resetFilters': undefined
  'cases.showFilters': undefined
  'cases.updateFilters': Partial<CaseFilters>
  'country.show': { country: string; case_id?: number }
  'dialog.hide': keyof typeof visible
  'dialog.show': keyof typeof visible
  'dialog.toggle': keyof typeof visible
  'hits.resolved': undefined
  'newCase.fill': NewCaseFill
  'notifications.show': undefined
  'organisations.updateSubPage': string
  'settings.updateSubPage': string
  'statistics.updateSubPage': string
  'dialogSupport.show': undefined | SupportDialogName
  'upgrade.show': undefined
  'users.filter': Partial<UserFilters>
  notify: undefined
  organizationSourcesImported: undefined
  reportDownloaded: Report
  search: SearchData
}

/**
 * Call the callback until it returns truthy.
 */
const until = <T extends keyof Events>(event: T, callback: (params: Events[T]) => unknown) => {
  const internalCallback = (params: Events[T]) => {
    if (callback(params)) {
      bus.off(event, internalCallback)
    }
  }

  bus.on(event, internalCallback)
}

/**
 * The callback is only called once.
 */
const once = <T extends keyof Events>(event: T, callback: (params: Events[T]) => void) => {
  const internalCallback = (params: Events[T]) => {
    callback(params)
    bus.off(event, internalCallback)
  }

  bus.on(event, internalCallback)
}

export type Bus = Emitter<Events> & { once: typeof once; until: typeof until }

export const bus: Bus = { ...mitt<Events>(), once, until }
bus.once = once
